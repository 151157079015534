import  React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import PastProjects from "../components/projects" 
import SEO from "../components/seo"
import { graphql } from "gatsby" 

class PastPage extends React.Component {
	render() {
			var data = this.props.data.allDatoCmsProject.nodes;
	    return (
		  <Layout internal={this.props.location.state?.internal}>
		   	<SEO title="Atlantis Property Group" /> 
		   	<Header />
		   	<PastProjects projects={data}/>
		  </Layout>
		)
	} 
}

export default PastPage

export const query = graphql`
  query PastQuery {
  	allDatoCmsProject(filter: {currentProject: {nin: true}}) {
	    nodes {
	      ...Project
	    }
	  }
  }
`
